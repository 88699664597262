import React, { useState, useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import BarChartIcon from "@mui/icons-material/BarChart";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MUIDataTable from "mui-datatables";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";

const DashBoard = () => {
  const [allData, setAllData] = useState([]);

  //on page load we are calling API
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND + "dashboard", {
        headers: { Authorization: `Bearer ${localStorage?.getItem("token")}` },
      })
      .then((resp) => {
        setAllData(resp && resp.data && resp.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //array for display the card details
  const list = [
    {
      name: "Total Active User",
      value: allData?.ActiveUsersCount,
      icon: <PeopleAltIcon className="fs-24" />,
    },
    {
      name: "Total Dossier",
      value: allData?.DossierDataCount,
      icon: <BarChartIcon className="fs-24" />,
    },
    {
      name: "Total Supplier",
      value: allData?.SupplierrDataCount,
      icon: <BarChartIcon className="fs-24" />,
    },
    {
      name: "Total Inactive User",
      value: allData?.InActiveUsers,
      icon: <NoAccountsIcon className="fs-24" />,
    },
  ];
  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "organisation",
      label: "Organisation",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div
              className={
                allData?.LatestUsers[dataIndex]?.status
                  ? "active-user"
                  : "inactive"
              }
            >
              <p className="fs-14 fw-600 m-1 ">
                {allData?.LatestUsers[dataIndex]?.status
                  ? "Active"
                  : "Inactive"}
              </p>
            </div>
          );
        },
      },
    },
  ];
  //option for mui datatable
  const options = {
    responsive: "standard",
    download: false,
    selectableRows: false,
    filter: false,
    search: false,
    viewColumns: false,
    serverSide: true,
    rowsPerPageOptions: [], // hide the "Rows per page" dropdown by providing an empty array
    rowsPerPage: 5,
  };
  return (
    <div className="p-4">
      <h2 className="primary_title text-start about_heading m-2 mb-3">
        DashBoard
      </h2>

      <div className="row bg_color mb-5 ">
        {list.map((item, index) => (
          <div className="col-sm-12 col-md-6 col-xl-3 p-0 ">
            <div
              className={
                index === 0
                  ? " border_radius m-2 card-dashboard card-border-shadow-primary "
                  : index === 1
                  ? " border_radius m-2 card-dashboard card-border-shadow-warning"
                  : index === 2
                  ? "  border_radius m-2 card-dashboard card-border-shadow-info"
                  : "border_radius m-2 card-dashboard card-border-shadow-danger"
              }
            >
              <div class=" d-flex card-body">
                <div
                  className={
                    index === 0
                      ? "mx-4 bg-1 "
                      : index === 1
                      ? "mx-4 bg-2 "
                      : index === 2
                      ? " mx-4 bg-3 "
                      : "mx-4 bg-4"
                  }
                >
                  <div className=" fs-20">{item.icon}</div>
                </div>

                <div className="cardCenter">
                  <p className="fs-14 fw-500 mb-2">{item?.name}</p>

                  <p className="fs-24 fw-700 mb-4">
                    {item?.value === "" ||
                    // item?.value !== null ||
                    item?.value === undefined
                      ? 0
                      : item?.value}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="row p-0">
        <div className="col-md-12 p-0">
          <h2 className="primary_title text-start about_heading m-2 mb-3">
            Latest User
          </h2>
          <MUIDataTable
            // title={"User Management"}
            data={allData?.LatestUsers}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};
export default DashBoard;
