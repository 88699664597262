import HomeIcon from "@mui/icons-material/Home";
import BarChartIcon from "@mui/icons-material/BarChart";
import PersonIcon from "@mui/icons-material/Person";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ForumIcon from "@mui/icons-material/Forum";
import VaccinesIcon from "@mui/icons-material/Vaccines";

export const navData = [
  {
    id: 0,
    icon: <HomeIcon />,
    text: "Dashboard",
    link: "/dashboard",
    role: ["admin"],
  },
  {
    id: 1,
    icon: <PersonIcon />,
    text: "User Management",
    link: "/user-management",
    role: ["admin"],
  },
  {
    id: 2,
    icon: <VaccinesIcon />,
    text: "Dossier",
    link: "/dossier-management",
    role: ["admin"],
  },
  {
    id: 3,
    icon: <AppRegistrationIcon />,
    text: "Supplier",
    link: "/supplier-management",
    role: ["admin"],
  },
];
