import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  IconButton,
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
} from "@mui/material";
import axios from "axios";
import { Delete, Edit, Close } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { toast } from "react-toastify";
const UserManagement = () => {
  const [user, setUser] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState();
  const [open, setOpen] = useState(false);
  const [opendel, setOpenDel] = useState(false);
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState(false);
  useEffect(() => {
    getAllUser(0, "");
  }, []);

  const getAllUser = (page, search) => {
    const data = {
      page: page,
      limit: 10,
      searchChar: search,
    };
    axios
      .post(process.env.REACT_APP_BACKEND + "get-all-users", data, {
        headers: { Authorization: `Bearer ${localStorage?.getItem("token")}` },
      })
      .then((resp) => {
        setUser(resp && resp.data && resp.data.data);
        setCount(resp && resp.data && resp.data.totalUsers);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "organisation",
      label: "Organisation",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div
              className={user[dataIndex]?.status ? "active-user" : "inactive"}
            >
              <p className="fs-14 fw-600 m-1 ">
                {user[dataIndex]?.status ? "Active" : "Inactive"}
              </p>
            </div>
          );
        },
      },
    },

    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        sort: false,

        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Tooltip title="Edit User">
                <IconButton
                  aria-label="Edit User"
                  onClick={() =>
                    handleOpen(
                      user[dataIndex]?._id,
                      "edit",
                      user[dataIndex]?.status
                    )
                  }
                >
                  <Edit className="fs-20 edit-icon" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete User">
                <IconButton
                  aria-label="delete User"
                  onClick={() => handleOpen(user[dataIndex]?._id, "delete")}
                >
                  <DeleteOutlineOutlinedIcon className="fs-20 delete-icon" />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];
  //option for mui datatable
  const options = {
    responsive: "standard",
    download: false,

    selectableRows: false,
    filter: false,
    viewColumns: false,

    serverSide: true,
    count: count,
    rowsPerPageOptions: [], // hide the "Rows per page" dropdown by providing an empty array
    rowsPerPage: 10,

    page,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        console.log(tableState.page, "Count");
        getAllUser(tableState.page, "");
        setPage(tableState.page);
      }
    },
    onSearchChange: (searchText) => {
      getAllUser(page, searchText);
    },
  };
  //Function to make dailog box open and set the state
  const handleOpen = (user, type, status) => {
    if (type === "delete") {
      setId(user);
      setOpenDel(true);
    } else if (type === "edit") {
      setId(user);
      setOpen(true);
      setType("edit");
      setStatus(status);
    }
  };

  //Function to make close the dailog box close
  const handleClose = () => {
    setOpen(false);
    setOpenDel(false);
    setType("");
    setStatus(false);
  };

  //
  const handleSubmit = () => {
    if (type === "edit") {
      const data = {
        id: id,
        status: !status,
      };
      axios
        .post(process.env.REACT_APP_BACKEND + "update-user", data, {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("token")}`,
          },
        })
        .then((resp) => {
          toast.success(resp && resp.data && resp.data.msg);
          getAllUser(0, "");
          handleClose();
        })
        .catch((err) => {
          toast.error(
            err && err.response && err.response.data && err.response.data.msg
          );
        });
    } else {
      const data = {
        id: id,
      };

      axios
        .post(process.env.REACT_APP_BACKEND + "delete-users", data, {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("token")}`,
          },
        })
        .then((resp) => {
          toast.success(resp && resp.data && resp.data.msg);
          getAllUser(0, "");
          handleClose();
        })
        .catch((err) => {
          toast.error(
            err && err.response && err.response.data && err.response.data.msg
          );
        });
    }
  };
  return (
    <div className="p-4">
      <h2 className="primary_title text-start about_heading m-2 mb-5">
        User Management
      </h2>
      <MUIDataTable
        // title={"User Management"}
        data={user}
        columns={columns}
        options={options}
      />

      {/* Dialog box foe create and edit opetator */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        open={open}
      >
        <div className="d-flex justify-content-between align-items-center p-3">
          <h3 className="mb-0">Update User Status</h3>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        <DialogContent dividers>
          <p className="fs-14 fw-500">
            {status
              ? "Are you sure want to Inactive User?"
              : "Are you sure want to Active User?"}
          </p>
          <div className="text-center mt-4">
            <Button
              variant="outlined"
              className="borderbtn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="btn ms-4"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {/* Dialog box to delete the operator */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        open={opendel}
      >
        <div className="d-flex justify-content-between align-items-center p-3">
          <h3 className="mb-0">Delete User</h3>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        <DialogContent dividers>
          <p className="fs-14 fw-500">
            Are you sure you want to delete the User ?
          </p>
        </DialogContent>

        <div className="text-center mt-4 mb-4" dividers>
          <Button
            variant="outlined"
            className="borderbtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="btn ms-4"
            onClick={handleSubmit}
          >
            Delete
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default UserManagement;
