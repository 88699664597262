// AppRouter.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../screens/Login";
import ProtectedRoute from "./ProtectedRoutes";
import DashBoard from "../screens/DashBoard";
import UserManagement from "../screens/UserManagement";
import Dossier from "../screens/Dossier";
import Supplier from "../screens/Supplier";
const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/login" element={<Login />} />

        {/* -------------------Admin Routes------------------------------------ */}
        <Route
          exact
          path="/admin"
          element={<ProtectedRoute allowedRoles={["admin"]} />}
        >
          {/* <Route exact path="/admin/data-form" element={<FillDetails />} /> */}
        </Route>

        <Route
          exact
          path="/"
          element={<ProtectedRoute allowedRoles={["seller", "admin"]} />}
        >
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/dossier-management" element={<Dossier />} />
          <Route path="/supplier-management" element={<Supplier />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
