import React, { useState } from "react";
import { navData } from "./sidenav/SidenavData";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { NavLink } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import "./sidenav/sidenav.css";

const SideNav = () => {
  const [open, setopen] = useState(true);
  const toggleOpen = () => {
    setopen(!open);
  };
  const role = localStorage.getItem("role");
  return (
    <div
      className={open ? "sidenav" : "sidenavClosed"}
      // onMouseEnter={toggleOpen}
      // onMouseLeave={toggleOpen}
    >
      <div className="text-right">
        <button className="menuBtn" onClick={toggleOpen}>
          {open ? <ArrowCircleLeftIcon /> : <ArrowCircleRightIcon />}
        </button>
      </div>
      {console.log(navData, "navDAta")}
      {navData.map((item) => {
        return item.role.includes(role) ? (
          <NavLink key={item.id} className="sideitem" to={item.link}>
            {item.icon}
            <span className={open ? "linkText" : "linkTextClosed"}>
              {item.text}
            </span>
          </NavLink>
        ) : (
          <></>
        );
      })}
      <p className={open ? "foot_head fs-12 me-2 fw-600" : "linkTextClosed"}>
        Powered by RevaaloLabs
        <span>
          <FavoriteIcon className="ms-2 text-danger" />
        </span>
      </p>
    </div>
  );
};

export default SideNav;
