import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  IconButton,
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
} from "@mui/material";
import axios from "axios";
import { Edit, Close } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { toast } from "react-toastify";
const Dossier = () => {
  const [dossier, setDossier] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState();
  const [open, setOpen] = useState(false);
  const [opendel, setOpenDel] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState(false);
  const [file, setFile] = useState("");
  useEffect(() => {
    getAllDossier(0, "");
  }, []);

  const getAllDossier = (page, search) => {
    const data = {
      page: page,
      limit: 10,
      searchChar: search,
    };
    axios
      .post(process.env.REACT_APP_BACKEND + "get-dossier", data, {
        headers: { Authorization: `Bearer ${localStorage?.getItem("token")}` },
      })
      .then((resp) => {
        setDossier(resp && resp.data && resp.data.data);
        setCount(resp && resp.data && resp.data.totalcount);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const columns = [
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "code",
      label: "Code",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "locations",
      label: "Location",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        sort: false,

        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Tooltip title="Delete Dossier">
                <IconButton
                  aria-label="delete Dossier"
                  onClick={() => handleOpen(dossier[dataIndex]?._id, "delete")}
                >
                  <DeleteOutlineOutlinedIcon className="fs-20 delete-icon" />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Edit Dossier">
                <IconButton
                  aria-label="Edit Dossier"
                  onClick={() =>
                    handleOpen(
                      dossier[dataIndex]?._id,
                      "edit",
                      dossier[dataIndex]?.status
                    )
                  }
                >
                  <Edit />
                </IconButton>
              </Tooltip> */}
            </>
          );
        },
      },
    },
  ];
  //option for mui datatable
  const options = {
    responsive: "standard",
    download: false,
    selectableRows: false,
    filter: false,
    viewColumns: false,
    serverSide: true,
    count: count,
    rowsPerPageOptions: [], // hide the "Rows per page" dropdown by providing an empty array
    rowsPerPage: 10,
    page,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        console.log(tableState.page, "Count");
        getAllDossier(tableState.page, "");
        setPage(tableState.page);
      }
    },
    onSearchChange: (searchText) => {
      getAllDossier(page, searchText);
    },
  };
  //Function to make dailog box open and set the state

  const handleOpen = (dossier, type, status) => {
    if (type === "delete") {
      setId(dossier);
      setOpenDel(true);
    } else if (type === "edit") {
      setId(dossier);
      setOpen(true);
      setType("edit");
      setStatus(status);
    } else if (type === "upload") {
      setOpenUpload(true);
      setType("upload");
    }
  };

  //Function to make close the dailog box close
  const handleClose = () => {
    setOpen(false);
    setOpenDel(false);
    setType("");
    setFile("");
    setStatus(false);
    setOpenUpload(false);
  };

  //
  const handleSubmit = () => {
    if (type === "edit") {
      const data = {
        id: id,
        status: !status,
      };
      axios
        .post(process.env.REACT_APP_BACKEND + "update-dossier", data, {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("token")}`,
          },
        })
        .then((resp) => {
          toast.success(resp && resp.data && resp.data.msg);
          getAllDossier(0, "");
          handleClose();
        })
        .catch((err) => {
          toast.error(
            err && err.response && err.response.data && err.response.data.msg
          );
        });
    } else if (type === "upload") {
      let formData = new FormData();
      formData.append("file", file);

      axios
        .post(process.env.REACT_APP_BACKEND + "create-dossier", formData, {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("token")}`,
          },
        })
        .then((resp) => {
          toast.success(resp && resp.data && resp.data.msg);
          getAllDossier(0, "");
          handleClose();
        })
        .catch((err) => {
          toast.error(
            err && err.response && err.response.data && err.response.data.msg
          );
        });
    } else {
      const data = {
        id: id,
      };

      axios
        .post(process.env.REACT_APP_BACKEND + "delete-dossier", data, {
          headers: {
            Authorization: `Bearer ${localStorage?.getItem("token")}`,
          },
        })
        .then((resp) => {
          toast.success(resp && resp.data && resp.data.msg);
          getAllDossier(0, "");
          handleClose();
        })
        .catch((err) => {
          toast.error(
            err && err.response && err.response.data && err.response.data.msg
          );
        });
    }
  };
  const handleFileChange = (event) => {
    const files = event.target.files[0];
    setFile(files);
  };
  return (
    <div className="p-4">
      <div
        className="mb-5"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 className="primary_title text-start about_heading m-2 mb-3">
          Dossier Management
        </h2>
        <Button
          variant="contained"
          className="btn ms-4"
          onClick={() => handleOpen("", "upload")}
        >
          Upload CSV
        </Button>
      </div>

      <MUIDataTable
        // title={"Dossier Management"}
        data={dossier}
        columns={columns}
        options={options}
      />

      {/* Dialog box foe create and edit opetator */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        open={open}
      >
        <div className="d-flex justify-content-between align-items-center p-3">
          <h3 className="mb-0">Edit Dossier Status</h3>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        <DialogContent dividers>
          <p className="fs-14 fw-500">
            {status
              ? "Are sure want to Inactive Dossier.?"
              : "Are sure want to Active Dossier.?"}
          </p>
          <div className="text-center mt-4">
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              className="small-button ms-4"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {/* Dialog box to delete the operator */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        open={opendel}
      >
        <div className="d-flex justify-content-between align-items-center p-3">
          <h3 className="mb-0">Delete Dossier</h3>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        <DialogContent dividers>
          <p className="fs-14 fw-500">
            Are you sure you want to delete the Dossier ?
          </p>
        </DialogContent>

        <div className="text-center mt-4 mb-4" dividers>
          <Button
            variant="outlined"
            className="borderbtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="btn ms-4"
            onClick={handleSubmit}
          >
            Delete
          </Button>
        </div>
      </Dialog>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        open={openUpload}
      >
        <div className="d-flex justify-content-between align-items-center p-3">
          <h3 className="mb-0">Upload Dossier</h3>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        <DialogContent dividers>
          <div className="m-auto text-center">
            <input
              type="file"
              id="cda"
              onChange={handleFileChange}
              multiple // Allows multiple file selection if needed
            />
          </div>
        </DialogContent>

        <div className="text-center mt-4 mb-4" dividers>
          <Button
            variant="outlined"
            className="borderbtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="btn ms-4"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default Dossier;
